// ブックの紹介

<template lang="pug">
.my-introduction

  section.what-is-book
    h2.text-3xl.font-bold.text-teal-700.lg__text-center おもいでBookって？

    .mt-6.sm__mt-10
      img.block.w-full.rounded-full.mx-auto(src='../../assets/sample-01.jpg')

    p.mt-6.sm__mt-10.text-gray-700.sm__text-center
      | ハードカバー・20ページ構成のフォトブックです。
      br
      | 前年度の写真販売で購入済みのお写真や、ご自身でアップロードしたお写真を利用することで、
      | あなただけのブックを作ることができます。

  section.mt-12
    h2.text-3xl.font-bold.text-teal-700.lg__text-center 商品一例

    .flex.mt-6.sm__mt-10
      .flex-1.px-3.sm__px-4
        img.block.w-full(src='../../assets/sample-05.jpg')
      .flex-1.px_3.sm__px-4
        img.block.w-full(src='../../assets/sample-07.jpg')

    p.mt-6.sm__mt-10.text-gray-700.sm__text-center
      | 写真を入れ替えたり、文字やスタンプを追加したりすることも可能です。

    .flex.mt-6.sm__mt-10
      .flex-1.px-3.sm__px-4
        img.block.w-full(src='../../assets/sample-02.jpg')
      .flex-1.px-3.sm__px-4
        img.block.w-full(src='../../assets/sample-03.jpg')

    p.mt-6.sm__mt-10.text-gray-700.sm__text-center
      | ハードカバー仕様でしっかりとした作りになっています。

  footer.text-center.mt-10
    router-link.btn(
      :to='{ name: "MyHome" }'
    ) トップに戻る
</template>

<script>
export default {
  name: 'MyIntroduction'
}
</script>

<style lang="sass" scoped>
.my-introduction
  .what-is-book
    img
      max-width: 200px
      +sm
        max-width: 400px
</style>
